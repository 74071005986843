<template>
  <InputParameterConfig :verificationlist="verificationlist"></InputParameterConfig>
</template>

<script>
import InputParameterConfig from '../../bot/views/InputParameterConfig.vue';
export default {
  name: 'tds_file_config',
  // props: ['props'],
  data: function () {
    return {
      verificationlist: ["617ede8980f5fe12231769e6", "63afe12e6525000038007583", "63afe1386525000038007584", "61e9164ffc40e5435b7a6e44", "61e91673fc40e5435b7a6e47", "61e91692fc40e5435b7a6e68"]
    }
  },
  components: {
    InputParameterConfig
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      // this.botid = this.$route.params.botID;
      // console.log(this.botid);
    },
  },
  watch: {
  }
}
</script>
